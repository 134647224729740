import $ from "jquery";

import {
  getElement,
  getUID,
  getSelectorFromElement,
  getElementFromSelector,
  getTransitionDurationFromElement,
  triggerTransitionEnd,
  isElement,
  typeCheckConfig,
  isVisible,
  isDisabled,
  findShadowRoot,
  noop,
  getNextActiveElement,
  reflow,
  getjQuery,
  onDOMContentLoaded,
  isRTL,
  defineJQueryPlugin,
  execute,
  executeAfterTransition,
} from "./bootstrap/util/";

import Collapse from "./bootstrap/collapse";
import Modal from "./bootstrap/modal";
import dropdown from "./bootstrap/dropdown";

/* -------------------------------------------------------------------------- */
/* Navbar                                                                     */
/* -------------------------------------------------------------------------- */

const menuOpen = document.querySelector('[data-bs-toggle="side-navbar"]');
const menuClose = document.querySelector(".menu-closed");
const mainMenu = document.querySelector(".side-navbar");
const logo = document.querySelector(".logo");
const navOverlay = document.querySelector(".nav-overlay");
const html = document.querySelector("html");

function openMenu() {
  menuClose.classList.toggle("menu-closed--active");

  mainMenu.classList.toggle("side-navbar--open");

  logo.classList.toggle("logo--display");

  navOverlay.classList.toggle("nav-overlay--open");

  html.classList.toggle("overflow-hidden");
}

menuOpen.addEventListener("click", function () {
  openMenu();
});

/* -------------------------------------------------------------------------- */
/* Loader                                                                     */
/* -------------------------------------------------------------------------- */
window.addEventListener("load", function () {
  document.querySelector("body").classList.remove("loading");
});
/* -------------------------------------------------------------------------- */
/*Our school video player                                                     */
/* -------------------------------------------------------------------------- */
const player = new Plyr("video");

// Expose player so it can be used from the console
window.player = player;
